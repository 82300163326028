<template>
  <div class="dash">
    <section id="heading" class="container">
      <h2>
        cute cute
        <small>what did you think of his profile?</small>
      </h2>
    </section>

    <section id="profile" class="container article">
      <div class="row">
        <div class="col-sm-12 col-md-12">
          <div class="card fluid card_large">
            <img :src="this.user.avatar" alt="avatar xanax666" />

            <div class="text">
              <h2>
                {{ this.user.name  }}
                <small>{{ this.getRole() }}</small>
                
                <small>
                  {{ this.getBio().toLowerCase() }}
                </small>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div id="configs" class="container article">
      <div class="row">
        <div class="col-sm-12 col-md-3 sm-space">
          <div class="card fluid">
            <h3>
              subscription
              <small>{{ this.user.subscription }}</small>
            </h3>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 sm-space">
          <div class="card fluid">
            <h3>
              joined
              <small>{{ this.user.joined }}</small>
            </h3>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 sm-space">
          <div class="card fluid">
            <h3>
              last visited
              <small>{{ this.user.last_visited }}</small>
            </h3>
          </div>
        </div>

        <div class="col-sm-12 col-md-3 sm-space">
          <div class="card fluid">
            <h3>
              configs
              <small>{{ this.user.configs }}</small>
            </h3>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "@/assets/css/dash.css";
import request from "@/utils/axios";

export default {
  name: "Profile",
  data() {
    return {
      id: Number,
      user: Object
    };
  },
  methods: {
    getRole() {
      let roles = {
        0: 'customer',
        666: 'depressive'
      };

      if (this.user.subscription == 'expired') {
        return 'member';
      }

      return roles[this.user.role];
    },
    getBio() {
      let lyrics = [
        'Wait right here',
        'I\'ll be back in the morning',
        'I know that I\'m not that important to you',
        'But to me, girl, you\'re so much more than gorgeous',
        'So much more than perfect',
        'Right now I know that I\'m not really worth it',
        'If you give me time, I could work on it',
        'Give me some time while I work on it'
      ];

      return lyrics[Math.floor(
        (Math.random() * lyrics.length)
      )];
    }
  },
  created() {
    this.id = Number(this.$route.params.id);

    if (isNaN(this.id)) {
      this.$router.push("/");
    }

    request
      .get("/profile/" + this.id)
      .then((response) => {
        this.user = response.data.data;
      })
      .catch(() => {
        this.$router.push('/');
      });
  },
};
</script>

<style scoped>
.card {
  text-align: center;
}

.card_large {
  justify-content: unset;
  text-align: start !important;
}

.card_large img {
  width: 115px;
  height: 115px;
  border-radius: 50%;
  object-fit: cover;
}

.card_large .text {
  margin: 0 0 0 10px;
}
</style>
